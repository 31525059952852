/* ==========================================================================
   Main content
   ========================================================================== */
.l-main {
    position: relative;
    min-height: 100vh;
}

.l-main__content {
    position: relative;
    z-index: 2;
    padding: 200px 0 100px;
}
