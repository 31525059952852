/* ==========================================================================
   Forms
   ========================================================================== */
.f-form {
    /* Reset default form styles + tag styles
       ========================================================================== */
    fieldset {
        margin: 0;
        padding: 0;

        border: 0;
    }
    input,
    textarea,
    select {
        border-radius: 0;
        box-shadow: none;
        &:focus {
            outline: none;
        }
    }
    textarea {
        min-height: $size-btn*5;
        max-height: $size-btn*10;
        padding: $size-gutter;

        resize: vertical;
    }
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px white inset;
    }
    *::-ms-clear {
        display: none;
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: currentColor;
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
        color: currentColor;
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
        color: currentColor;
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: currentColor;
    }
    /* Form elements wrappers
       ========================================================================== */
    .f-input-wrapper,
    .f-select-wrapper,
    .f-textarea-wrapper {
        position: relative;

        cursor: pointer;
    }
    .f-input-wrapper,
    .f-textarea-wrapper {
        @include helper--align--stretch;

        display: flex;
        input,
        textarea {
            width: 100%;

            border: none;
            background: transparent;
        }
    }
    .f-input-wrapper {
        input {
            width: 100%;
            height: $size-btn;
            // padding: 0 calc(#{$size-btn} + #{$size-gutter} - 8px) 0 $size-gutter;
        }
    }
    .f-textarea-wrapper {
        // textarea {
        //     padding-right: calc(#{$size-gutter} * 2 + 20px );
        // }
    }
    .f-select-wrapper {
        position: relative;

        overflow: hidden;
        select {
            position: relative;
            z-index: 3;

            width: 100%;
            height: $size-btn;
            // padding: 0 calc(#{$size-btn} + #{$size-gutter} - 8px) 0 $size-gutter;

            border: none;
            border-radius: 0;
            background: transparent;

            -webkit-appearance: none;
               -moz-appearance: none;
        }
        select::-ms-expand {
            display: none;
        }
    }
}
