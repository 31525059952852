/* ==========================================================================
   Cantor layout
   ========================================================================== */
   
.l-cantor {
    position: relative;
    background-color: $color-white;
    color: $color-black;
    padding: 50px;
    box-shadow: 3px 3px 4px 3px $color-shadow;
    border-radius: 30px;
}

.l-cantor__heading {
    color: $color-default;
    font-size: 20px;
}

@include media('<=phone') {
    .l-cantor {
        padding: 50px 0;
    }
    .l-cantor__content {
        padding: 0;
    }
}
