/* ==========================================================================
   Table component
   ========================================================================== */

.c-table  {
    border-collapse:collapse;
    border-spacing:0;
    border-color:$color-grey-2;
}

.c-table__heading {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 5px;
    border: 1px solid $color-grey-2;
    overflow: hidden;
    word-break: normal;
    color: $color-white;
    background-color: $color-blue;
    vertical-align: middle;
}

.c-table__category {
    vertical-align: top;
    font-weight: 600;
    text-align: left;
    color: $color-black;
    font-size: 14px;
    padding: 10px 5px;
    border: 1px solid $color-grey-2;
    overflow: hidden;
    word-break: normal;
    background-color: $color-grey;
}

.c-table__value {
    vertical-align: top;
    font-weight: 400;
    text-align: right;
    font-size: 14px;
    padding: 10px 5px;
    border: 1px solid $color-grey-2;
    overflow: hidden;
    word-break: normal;
    color: $color-grey-3;
    background-color: $color-white;
}

@include media('<=phone') {
    .c-table-wrap {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
