/* ==========================================================================
   User interface
   ========================================================================== */

// .ui-blend-multiply {
//     mix-blend-mode: multiply;
// }
// .ui-blend-mask {
//     opacity: .8;
//     background: currentColor;
//     &--whole-vertical {
//         height: 100%;
//     }
//     &--whole-horizontal {
//         width: 100%;
//     }
// }
// .ui-blend-parent {
//     position: relative;
//     .ui-blend-mask {
//         position: absolute;
//     }
// }