/* ==========================================================================
   Logos
   ========================================================================== */
.c-logo {
    font-size: 40px;
    font-family: $font-quicksand;
    font-weight: 400;
    text-decoration: none;
    color: $color-white;
}

.c-logo__segment {
    font-weight: bold;
}
