/* ==========================================================================
   Mine layout
   ========================================================================== */
   
.l-mine { 
    position: relative;
    background-color: $color-white;
    color: $color-black;
    padding: 50px;
    box-shadow: 3px 3px 4px 3px $color-shadow;
    border-radius: 30px;
    margin-bottom: 40px;
    &:last-child() {
        margin-bottom: 0;
    }
}

.l-mine__heading {
    color: $color-default;
    font-size: 20px;
}

@include media('<=phone') {
    .l-mine {
        padding: 20px 0;
    }
    .l-mine__content {
        padding: 0;
    }
}
