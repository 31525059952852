/* ==========================================================================
   Mine list component
   ========================================================================== */
   
.c-mine-list {
    margin-top: 20px;
}

.c-mine-list__item {
    padding: 0;
    font-size: 16px;
}

.c-mine-sublist {
    list-style-type: lower-alpha;
    color: $color-grey-2;
    padding-top: 5px;
    padding-bottom: 5px;
}

.c-mine-sublist__item {
    padding: 0;
    font-size: 16px;
}

@include media('<=phone') {
    .c-mine-list__item {
        font-size: 14px;
    }
    .c-mine-sublist__item {
        font-size: 14px;
    }
}
