/* ==========================================================================
   Animations
   ========================================================================== */


/* Bouncing animation
   ========================================================================== */
// @keyframes bouncingAnimation {
//     0% {
//         transform: translate3d(0,-5px,0);
//     }
//     50% {
//         transform: translate3d(0,0,0);
//     }
//     100% {
//         transform: translate3d(0,-5px,0);
//     }
// }
// .ui-bounce-anim {
//     animation-name: bouncingAnimation;
//     animation-duration: 1s;
//     animation-iteration-count: infinite;
//     &:hover {
//         @include helper--stop-animation;
//     }
// }
