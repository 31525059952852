/* ==========================================================================
   FAQ components
   ========================================================================== */

.c-faq {
    padding-bottom: 20px;
    border-bottom: 2px dotted $color-grey;
}

.c-faq__heading {
    color: $color-default;
}

.c-faq__heading:first-letter {
    font-weight: 900;
}
