/* ==========================================================================
   Hero Section
   ========================================================================== */
.l-hero {
    position: relative;
    height: 100vh;
    color: $color-white;
}

.l-hero__title {
    margin-bottom: 0;
}

.l-hero__slogan {
    margin-top: 0;
}

.l-hero__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.l-hero__content {
    position: relative;
    z-index: 2;
    padding: 30vh 0 10vh;
}
