/* ==========================================================================
   Utilities
   ========================================================================== */

/* Text indent
========================================================================== */
.u-info {
    @include helper--indent;
}

/* Background attachment fixed
   ========================================================================== */
.u-bg-fixed {
    background-attachment: fixed;
    background-size: cover;
}
.u-bg-fixed {
    @include media('<=tablet') {
        background-attachment: scroll;
    }
}

/* Gutters
   ========================================================================== */
.u-gutter-top {
    margin-top: 40px;
}
.u-gutter-top--mid {
    margin-top: 70px;
}
.u-gutter-top--big {
    margin-top: 100px;
}

@include media('<=phone') {
    .u-gutter-top--mid {
        margin-top: 25px;
    }
}

/* Backgrounds
   ========================================================================== */
@for $i from 1 through length($colors) {
    .u-bg-#{$i} {
        background-color: nth($colors, $i);
    }
}
@for $i from 1 through length($colors) {
    .u-bg-#{$i}-on-hover {
        &:hover {
            background-color: nth($colors, $i);
        }
    }
}

// for double horizontal background
// @for $i from 1 through length($colors) {
//     @for $j from 1 through length($colors) {
//         .u-bg-double-#{$i}-#{$j} {
//             background: linear-gradient(to left, nth($colors, $i) 0%,nth($colors, $i) 50%, nth($colors, $j) 50%,nth($colors, $j) 100%);
//         }
//     }
// }
// .u-bg-current {
//     background-color: currentColor;
// }
// .u-bg-current-on-hover {
//     &:hover {
//         background-color: currentColor;
//     }
// }
// .u-bg-transparent {
//     background-color: transparent;
// }
// .u-bg-transparent-on-hover {
//     &:hover {
//         background-color: transparent;
//     }
// }

/* Paints (colors)
========================================================================== */
@for $i from 1 through length($colors) {
    .u-paint-#{$i} {
        color: nth($colors, $i);
    }
}
// if we want to change colors on different screens resolutions we need to generate griddle class instead of regular class
// @for $i from 1 through length($colors) {
//     @include gridle_generate_custom_class( ('u','-','paint','-','#{$i}','@','%state') ) {
//         color: nth($colors, $i);
        
//         content: '#{gridle_get_current_state_name()}';
//     }
// }

@for $i from 1 through length($colors) {
    .u-paint-#{$i}-on-hover {
        &:hover {
            color: nth($colors, $i);
        }
    }
}
// .u-paint-current-on-hover {
//     &:hover {
//         color: currentColor;
//     }
// }

/* Borders
========================================================================== */
// @for $i from 1 through length($colors) {
//     .u-border-#{$i} {
//         border-color: nth($colors, $i);
//     }
// }

// @for $i from 1 through length($colors) {
//     .u-border-#{$i}-on-hover {
//         &:hover {
//             border-color: nth($colors, $i);
//         }
//     }
// }
// .u-border-current {
//     border-color: currentColor;
// }
// .u-border-current-on-hover {
//     &:hover {
//         border-color: currentColor;
//     }
// }

/* Change element color/background on parent hover
========================================================================== */
/* Example:
    You set following colors in variables file: $color_1: black; $color_2: yellow;
    You have button with following classes="u-paint-1 u-bg-2" ("u-paint-1" class styles: color: $color_1 (= black). "u-bg-2" class styles: background-color: $color_2 (= yellow)) inside some box (it doesn't have to be direct parent of this button).
    You want the button switch colors on the box hover state - it means have reverse color/background: color: yellow; and background-color: black;
    You need to give "u-rev-on-hover-parent@parent" class to the box and "u-rev-on-hover-parent@child" class to the button
*/
// .u-rev-on-hover-parent\@parent {
//     &:hover {
//         .u-rev-on-hover-parent\@child {
//             @for $i from 1 through length($colors) {
//                 &.u-paint-#{$i} {
//                     background-color: nth($colors, $i);
//                 }
//                 &.u-bg-#{$i} {
//                     color: nth($colors, $i);
//                 }
//             }
//         }
//     }
// }

/* Example:
    You set following colors in variables file: $color_1: black; $color_2: purple; $color_3: red; $color_4: yellow;
    You have button with following classes="u-paint-1 u-bg-2" ("u-paint-1" class styles: color: $color_1. "u-bg-2" class styles: color: $color_2) inside some box (it doesn't have to be direct parent of this button).
    You want the button change its color on $color_3 and its background on $color_4 while the box is hovered.
    You need to give "u-trigger-action-on-parent-hover@parent" class to the box and "u-trigger-action-on-parent-hover@child" classes to the button
*/
// .u-trigger-action-on-parent-hover\@parent {
//     .u-trigger-action-on-parent-hover\@child {
//         transition: ease-in-out .5s;
//     }
//     &:hover {
//         @for $i from 1 through length($colors) {
//             .u-trigger-action-on-parent-hover\@child {
//                 &.u-paint-#{$i}-on-hover {
//                     color: nth($colors, $i);
//                 }
//             }
//         }
//     }
// }


/* Translates
========================================================================== */
// .u-horizontal-middle-right {
//     transform: translateX(50%);
// }
// .u-horizontal-middle-left {
//     transform: translateX(-50%);
// }
// .u-vertical-middle-top {
//     transform: translateY(-50%);
//     &.u-horizontal-middle-right {
//         transform: translate(50%, -50%);
//     }
//     &.u-horizontal-middle-left {
//         transform: translate(-50%, -50%);
//     }
//     &.u-horizontal-total-right {
//         transform: translateX(100%, -50%);
//     }
//     &.u-horizontal-total-left {
//         transform: translateX(-100%, -50%);
//     }
// }
// .u-vertical-middle-bottom {
//     transform: translateY(50%);
//     &.u-horizontal-middle-right {
//         transform: translate(50%, 50%);
//     }
//     &.u-horizontal-middle-left {
//         transform: translate(-50%, 50%);
//     }
//     &.u-horizontal-total-right {
//         transform: translateX(100%, 50%);
//     }
//     &.u-horizontal-total-left {
//         transform: translateX(-100%, 50%);
//     }
// }
// .u-horizontal-total-right {
//     transform: translateX(100%);
// }
// .u-horizontal-total-left {
//     transform: translateX(-100%);
// }
// .u-vertical-total-top {
//     transform: translateY(-100%);
//     &.u-horizontal-middle-right {
//         transform: translate(50%, -100%);
//     }
//     &.u-horizontal-middle-left {
//         transform: translate(-50%, -100%);
//     }
//     &.u-horizontal-total-right {
//         transform: translateX(100%, -100%);
//     }
//     &.u-horizontal-total-left {
//         transform: translateX(-100%, -100%);
//     }
// }
// .u-vertical-total-bottom {
//     transform: translateY(100%);
//     &.u-horizontal-middle-right {
//         transform: translate(50%, 100%);
//     }
//     &.u-horizontal-middle-left {
//         transform: translate(-50%, 100%);
//     }
//     &.u-horizontal-total-right {
//         transform: translateX(100%, 100%);
//     }
//     &.u-horizontal-total-left {
//         transform: translateX(-100%, 100%);
//     }
// }

// .u-nowrap {
//     white-space: nowrap;
// }
// .u-wrap {
//     white-space: wrap;
// }