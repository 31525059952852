/* ==========================================================================
   Links
   ========================================================================== */

.c-link-btn {
    display: inline-block;
    margin-top: 10px;
    min-width: 130px;
    background-color: transparent;
    color: $color-white;
    border: 1px solid $color-white;
    border-radius: 10px;
    font-size: 25px;
    padding: 10px 15px;
    text-decoration: none;
    text-align: center;
    transition: 0.2s linear;
}

.c-link-btn:hover {
    background-color: $color-white;
    color: $color-black;
}

.c-link-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .c-link-btn {
        margin-right: 10px;
    }
}

@include media('<=phone') {
    .c-link-btn {
        font-size: 20px;
        padding: 5px 10px;
    }
}
