/* ==========================================================================
   Reset
   ========================================================================== */
* {
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
}

svg {
    fill: currentColor;
}

iframe,
img {
    display: block;

    max-width: 100%;
}

iframe {
    border: none;
}

figure,
blockquote {
    margin: 0;
}
