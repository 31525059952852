/* ==========================================================================
   Icons
   ========================================================================== */

/* styles for fontello icons
.o-icon {
    font-family: $font-ico;
    line-height: 1;

    @extend %helper--inline-flex--center--center;
    &:before {
        margin: 0;

        line-height: 1;

        @extend %helper--inline-flex--center--center;
    }
}
*/

