/* ==========================================================================
   Variables
   ========================================================================== */

/* Colors
========================================================================== */
$color-default: #464646;
$color-black: #000;
$color-white: #fff;
$color-shadow: #1C3D55;
$color-grey: #d3d3d3;
$color-grey-2: #6A6A6A;
$color-grey-3: #444444;
$color-blue: #26ADE4;

$color-1: $color-black;
$color-2: $color-white;
$color-3: #d4213d;

$colors:
    $color-1,
    $color-2,
    $color-3;

$color-default-link: $color-3;

/* Indexes
========================================================================== */
$index-header: 100;

/* Sizes
========================================================================== */
$size-inner: 1240px;
$size-gutter: 30px;

$size-btn: 48px;

$size_scalable-padding_16-9: 56%;
$size_scalable-padding_4-3: 75%;


/* Fonts
========================================================================== */
$font-default: 'Open Sans', sans-serif;
$font-quicksand: 'Quicksand', sans-serif;
$font-ico: 'fontello';

/* Breakpoints
========================================================================== */

// breakpoints names used for include-media breakpoint and for gridle
$breakpoint-desktop-small: 'desktop-small';
$breakpoint-tablet: 'tablet';
$breakpoint-phone: 'phone';

// breakpoints values used for include media and for grid gridle
$size-desktop-small: $size-inner + $size-gutter*4;
$size-tablet: 1024px;
$size-phone: 767px;

// breakpoints names array - used to generate custom attributes with include-media rwd
$breakpoints_array:
    $breakpoint-desktop-small,
    $breakpoint-tablet,
    $breakpoint-phone;

// breakpoints values array - used to generate custom attributes with include-media rwd and for include-media itself
$breakpoints: (
    $breakpoint-desktop-small: $size-desktop-small,
    $breakpoint-tablet: $size-tablet,
    $breakpoint-phone: $size-phone  
);

$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)'
) !default;

// breakpoint exaples
// body {
//     @include media('<=desktop-small') {
//         background: pink;
//     }
//     @include media('<=tablet') {
//         background: red;
//     }
//     @include media('<=phone') {
//         background: yellow;
//     }
//     @include media('>phone', '<=desktop-small') {
//         border: 5px solid grey;
//     }
//     @include media('>tablet', '<=1100px') {
//         color: red;
//     }
//     @include media('<=phone', 'landscape') {
//         background: orange;
//     }
// }
