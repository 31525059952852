/* ==========================================================================
   Clearfixes
   ========================================================================== */
%helper--clear {
    display: block;
    visibility: hidden;
    clear: both;
    overflow: hidden;

    height: 0;
    margin: 0!important;

    line-height: 0;

    content: '.';
}

%helper--clear-after {
    display: block;
    clear: both;

    margin: 0!important;

    content: '.';
}

%helper--clearfix {
    &:before,
    &:after {
        display: block;
        visibility: hidden;

        height: 0;

        content: '\0020';
    }
    &:after {
        clear: both;
    }
}
