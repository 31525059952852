/* ==========================================================================
   Header
   ========================================================================== */

.l-header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 60px 0;
}
