/* ==========================================================================
   Buttons
   ========================================================================== */

// .c-btn {
//     cursor: pointer;

//     border: none;
//     .c-label {
//         width: 100%;

//         text-align: center;
//     }
// }