/* ==========================================================================
   COMMON
   ========================================================================== */
// [class*='t-default'] {
//     line-height: 1;
//     > a {
//         @include helper--transition;
//     }
// }

.t-upper {
    text-transform: uppercase;
}


/* ==========================================================================
   Font families
   ========================================================================== */
/*
 * font families used in project:
 *
 * $font-default: 'opensans'
 *
 */

/*
 * font weights used in project:
 *
 * $weight_regular:   400;  // Oswald, Opensans
 * $weight_bold:      600;  // Oswald, Opensans
 *
 */

/* Font default (opensans)
   ========================================================================== */
// [class*='t-default'] {
//     font-family: $font-default;
// }


/* ==========================================================================
   FONT SIZES
   ========================================================================== */
/*
 * font sizes used in project:
 *
 * t-size-s:       16px
 * t-size-m:       18px
 * t-size-l:       20px
 *
 */

/* size s: 16px
   ========================================================================== */
// .t-size-s {
//     font-size: $font_s;
// }
