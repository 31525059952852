/* ==========================================================================
   Typography
   ========================================================================== */


/* Global texts
========================================================================== */
html {
    @include font-smoothing(on);

    font-size: 16px;
}
body {
    font-family: $font-default;
    line-height: 1.4;
    background: linear-gradient(to right, #09203f, #537895); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: $color_default;
}

a {
    cursor: pointer;
    text-decoration: underline;

    color: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    &:not([class]) + &:not([class]) {
        margin-top: $size-gutter;
    }
}



ul,
ol {
    @include helper--list;

    padding-left: $size-gutter;
    &:not([class]) {
        padding-left: $size-gutter;
        li + li {
            margin-top: $size-gutter/2;
        }
    }
}

ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

.t-heading-1 {
    font-size: 80px;
    font-family: 'Quicksand', sans-serif;

    @include media('<=tablet') {
        font-size: 60px;
    }
    @include media('<=phone') {
        font-size: 32px;
    }
}
.t-heading-2 {
    font-weight: normal;
    font-size: 22px;

    @include media('<=phone') {
        font-size: 16px;
    }
}

.t-heading-3 {
    font-weight: bold;
    font-size: 36px;

    @include media('<=phone') {
        font-size: 25px;
        line-height: 1em;
    }
}

.t-text-1 {
    font-size: 16px;

    @include media('<=phone') {
        font-size: 14px;
    }
}

.t-bold {
    font-weight: 600;
}
