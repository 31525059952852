/* ==========================================================================
   Mixins
   ========================================================================== */

/* 1. Font smoothing
========================================================================== */
/* Better font-rendering on OSX
   https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
*/
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}
@mixin clearfix {
    &:after {
        display: block;
        visibility: hidden;
        clear: both;
        overflow: hidden;

        height: 0;
        margin: 0!important;

        line-height: 0;

        content: '.';
    }
}
@mixin transition($property: all) {
    transition: $property .1s ease-in-out;
}

